export default [
  {
    name: 'Jämför paket',
    href: '/paket',
  },
  {
    name: 'Standard',
    href: '/paket/standard',
  },
  {
    name: 'Plus',
    href: '/paket/plus',
  },
  {
    name: 'Premium',
    href: '/paket/premium',
  },
];
