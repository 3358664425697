import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import * as styles from './Badge.module.scss';

type BadgeProps = {
  color?: 'green' | 'red' | 'yellow' | 'pink' | 'green-soft' | 'purple-5';
  children?: React.ReactChild;
  style?: CSSProperties;
  className?: string;
};

function Badge({ children, color, className, ...rest }: BadgeProps) {
  const classes = classNames({
    [styles.badge]: true,
    [styles.green]: color === 'green',
    [styles.greenSoft]: color === 'green-soft',
    [styles.red]: color === 'red',
    [styles.yellow]: color === 'yellow',
    [styles.pink]: color === 'pink',
    [styles.purple5]: color === 'purple-5',
    [styles.smallRotation]: typeof children === 'string' && children.length > 8,
    [className]: className,
  });

  return (
    <span className={classes} {...rest}>
      {children}
    </span>
  );
}

export default Badge;
